@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@layer base {
  :root {
    /* Body Colors */
    --body-color-primary: #0f172a;
    --body-color-secondary: #020617;
    --body-color-tertiary: #1e293b;

    /* Text Colors */
    --text-color-primary: #ffffff;
    --text-color-secondary: #94a3b8;
    --text-color-tertiary: #000000;

    /* Button Colors */
    --button-color-primary: #008080;
    --button-text-primary: #000000;
    /* Add button-danger and button-secondary if you have specific colors */

    /* Navbar Colors */
    --navbar-active-color-primary: #008080;
    --navbar-active-text-color: #ffffff;

    /* Footer Colors */
    --footer-color-primary: #020617;
    --footer-text-color-primary: #94a3b8;
    // --tg-viewport-height: 100vh;
    // --tg-viewport-stable-height: 100vh;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  touch-action: none !important;
  overflow-x: hidden !important;
  overflow-y: auto;
  // background-color: #0f172a;
}

body.lock-scroll {
  overflow-y: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.containerContent {
  @apply p-2 overflow-hidden;
}

::-webkit-scrollbar {
  width: 4px; /* Width of the vertical scrollbar */
  height: 4px; /* Height for the horizontal scrollbar */
}

::-webkit-scrollbar-track {
  @apply bg-gray-200; /* Background color of the track */
}

::-webkit-scrollbar-thumb {
  @apply bg-gray-500; /* Style for the draggable part of the scrollbar */
}

::-webkit-scrollbar-thumb:hover {
  @apply bg-gray-700; /* Style for the draggable part on hover */
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.navigationActiveTab {
  background-color: var(--navbar-active-color-primary);
  opacity: 0.5;
}

// swiper

// .swiper-wrapper {
//   transition-timing-function: linear;
// }